import React from "react";
import "./Projekt.scss";

export default function Projekt1() {
  return (
    <div className="projekt">
      <img src="/wizualizacje/wizualizacja3.jpg" alt="" />
      <img src="/projekt1/poziom0.png" alt="" />
      <img src="/wizualizacje/wizualizacja1.jpg" alt="" />
      <img src="/projekt1/poziom3.png" alt="" />
      <img src="/projekt1/poziom5.svg" alt="" />

      <Description />
    </div>
  );
}

function Description() {
  return (
    <div>
      <h2>DOM JEDNORODZINNY W PIWNICZNEJ-ZDROJU</h2>
      <table>
        <tbody>
          <tr>
            <td>Lokalizacja:</td>
            <td>
              Kosarzyska, Piwniczna-Zdrój, województwo małopolskie, Polska
            </td>
          </tr>
          <tr>
            <td>Projekt:</td>
            <td>2018 r.</td>
          </tr>
          <tr>
            <td>Powierzchnia użytkowa:</td>
            <td>
              Poziom 0.00 - 46 m<sup>2</sup>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              Poziom 3.00 - 79 m<sup>2</sup>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              Poziom 5.80 - 48 m<sup>2</sup>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              Razem:
              <span className="spacer"/>
              173 m<sup>2</sup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
