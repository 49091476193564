import React, { Component } from "react";
import "./App.scss";
import Navigation from "./Navigation";
import Content from "./Content";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import CookieConsent from "react-cookie-consent";

function setSpacing() {
  const topSpacing = document.querySelector(".logo-box").clientHeight;
  document.documentElement.style.setProperty(
    "--top-spacing",
    topSpacing + "px"
  );
}
class App extends Component {
  componentDidMount() {
    setSpacing();

    window.addEventListener("resize", setSpacing);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", setSpacing);
  }
  render() {
    return (
      <>
        <Navigation />
        <Content />
        <TopBar />
        <BottomBar />
        <CookieConsent
          buttonText={"Rozumiem"}
          style={{ justifyContent: "center" }}
          contentStyle={{ flex: "0 1 auto" }}
        >
          Ta strona używa pliki cookies. Korzystając ze strony akceptujesz ich
          użycie.
        </CookieConsent>
      </>
    );
  }
}

export default App;
