import React from "react";
import "./OPracowni.scss";

export default function() {
  return (
    <div className="o-pracowni">
      <p>MGR INŻ. ARCH. MAGDALENA ZIELIŃSKA (ZAPAŁA)</p>
      <p>
        Urodzona w Rabce-Zdroju w 1989r.
        <br />
        Absolwentka Wydziału Architektury Politechniki Krakowskiej z roku 2013.
        <br />
        Od roku 2012 uzyskiwała doświadczenie zawodowe w krakowskiej pracowni BP
        PROJEKT.
        <br />
        Uprawnienia budowlane w specjalności architektonicznej do projektowania
        i kierowania robotami budowlanymi bez ograniczeń uzyskała w grudniu 2017
        roku.
        <br />
        Od kwietnia 2018 czynny członek Małopolskiej Okręgowej Izby Architektów
        RP.
      </p>
      <p>
        Podążając za mottem Leonarda da Vinci: „Prostota jest szczytem
        wyrafinowania” w swoich projektach stosuje rozwiązania, które dzięki
        prostocie, klarowności i syntezie w pełni wykorzystują potencjał i
        charakter miejsca.
      </p>
    </div>
  );
}
