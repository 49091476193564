import React from "react";

import "./Projekty.scss";
import { Link } from "@reach/router";

export default function() {
  return (
    <div className="projekty">
      <Images />
      <p>WSPÓŁAUTORSTWO WYBRANYCH PROJEKTÓW WYKONANYCH W BP PROJEKT:</p>
      <ul>
        <li>
          Das Haus dom jednorodzinny w zabudowie bliźniaczej,{" "}
          <a
            href="http://sztuka-architektury.pl/article/4133/cztery-razy-dwa"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
        <li>
          Talo dom jednorodzinny w zabudowie bliźniaczej,{" "}
          <a
            href="http://www.kecoco.com/talo/"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
        <li>
          Zadaszenie amfiteatru w Białej Podlaskiej,{" "}
          <a
            href="http://podlasie24.pl/miasto-biala-podlaska/region/tak-bedzie-wygladal-bialski-amfiteatr-po-remoncie-21fc4.html"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
        <li>
          Schronisko dla bezdomnych zwierząt w Krakowie,{" "}
          <a
            href="http://krakow.pl/aktualnosci/223911,29,komunikat,wkrotce_przebudowa_schroniska_dla_zwierzat.html"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
        <li>
          III nagroda w konkursie na park rekreacyjno-wypoczynkowy w
          Jankowicach,{" "}
          <a
            href="http://www.a-ronet.pl/index.php?mod=nagroda&n_id=4142"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
        <li>
          Wyróżnienie w konkursie na kompleks urządzeń uzdrowiskowych w
          Busku-Zdroju,{" "}
          <a
            href="http://www.a-ronet.pl/index.php?mod=nagroda&n_id=3928"
            rel="noopener noreferrer"
            target="_blank"
            alt=""
          >
            zobacz
          </a>
        </li>
      </ul>
    </div>
  );
}

function Square({ children }) {
  return (
    <div className="square">
      <div className="inner-square">{children}</div>
    </div>
  );
}

function Images() {
  const commonPath = "/wizualizacje/wizualizacja";
  return (
    <div className="projekty-images">
      <Square>
        <Link to="/projekty/projekt1">
          <figure>
            <img src={`${commonPath}3.jpg`} alt="" />
            <figcaption>Dom jednorodzinny w Piwnicznej-Zdroju</figcaption>
          </figure>
        </Link>
      </Square>
      <Square>
        <Link to="/projekty/projekt2">
          <figure>
            <img src={`${commonPath}2.jpg`} alt="" />
            <figcaption>
              Kuchnia w domu jednorodzinnym w Rabce-Zdroju
            </figcaption>
          </figure>
        </Link>
      </Square>
      <Square>
        <Link to="/projekty/projekt3">
          <figure>
            <img src={`${commonPath}4.jpg`} alt="" />
            <figcaption>Wnętrze domu w Piwnicznej-Zdroju</figcaption>
          </figure>
        </Link>
      </Square>
    </div>
  );
}
