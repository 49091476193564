import React from "react";
import { Link } from "@reach/router";
import "./Navigation.scss";
import { subpageRoutes } from "./routes";
import { homeRoute } from "./routes";
import { Menu } from "./Icons";

const isActive = ({ isCurrent, isPartiallyCurrent }) => {
  return isCurrent
    ? { className: "menu-link link-active" }
    : isPartiallyCurrent
    ? { className: "menu-link link-partially-active" }
    : null;
};

const NavItem = ({ title, route, className }) => {
  return (
    <li key={route}>
      <Link
        to={route}
        getProps={isActive}
        className={["menu-link", className].join(" ").trim()}
      >
        {title}
      </Link>
    </li>
  );
};

const ListItems = subpageRoutes.map(NavItem);

export default class Navigation extends React.Component {
  state = {
    isMenuOpen: false
  };

  toggleMenu = state => {
    if (typeof state === "boolean") {
      this.setState(() => ({ isMenuOpen: state }));
      return;
    }
    this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }));
  };
  render() {
    const ulClasses = "menu-list" + (this.state.isMenuOpen ? " menu-open" : "");
    return (
      <nav className="sidebar">
        <header className="logo-box">
          <Link
            to={homeRoute.route}
            className="logo-link"
            onClick={() => this.toggleMenu(false)}
          >
            <img
              src="/logo.svg"
              alt="Linia i Forma - Architekt Magdalena Zielińska"
              className="logo-img"
              width="310"
              height="69"
            />
          </Link>
          <Menu className="menu-icon" onClick={this.toggleMenu} />
        </header>
        <ul className={ulClasses} onClick={this.toggleMenu}>
          {ListItems}
        </ul>
      </nav>
    );
  }
}
