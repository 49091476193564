import React from "react";
import "./Kontakt.scss";

export default function() {
  return (
    <div className="kontakt">
      <p>
        LINIA I FORMA
        <br />
        Architekt Magdalena Zielińska
        <br />
        NIP: 7352879667,
        <br />
        REGON: 381472175
      </p>
      <p>
        E-mail: <a href="mailto:biuro@liniaiforma.pl">biuro@liniaiforma.pl</a>
        <br />
        Tel: <a href="tel:602195984">602 195 984</a>
      </p>
      <p>
        Adres korespondencyjny:
        <br />
        34-700 Rabka-Zdrój, ul. Bystra 11a
      </p>
      <p>Siedziba firmy: 33-350 Piwniczna-Zdrój, Kosarzyska</p>
      <p>
        
      <a
        className="facebook-link"
        href="https://www.facebook.com/pg/LINIA-I-FORMA-Architekt-Magdalena-Zieli%C5%84ska-1982038492098662/about/?ref=page_internal"
        rel="noopener noreferrer"
        target="_blank"
        style={{color: "#3d5b99"}}
      >
      Znajdź nas na Facebooku
      </a>
      </p>
    </div>
  );
}
