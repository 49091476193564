import React from "react";
import "./Projekt.scss";

export default function Project2() {
  return (
    <div className="projekt">
      <img src="/wizualizacje/wizualizacja2.jpg" alt="" />
      <img src="/projekt2/rys0.svg" alt="" />
      <Description />
    </div>
  );
}

function Description() {
  return (
    <div>
      <h2>KUCHNIA W RABCE-ZDROJU</h2>
      <table>
        <tbody>
          <tr>
            <td>Lokalizacja:</td>
            <td>Ul. Bystra, Rabka-Zdrój, województwo małopolskie, Polska</td>
          </tr>
          <tr>
            <td>
              Projekt:
            </td>
            <td>
              2018 r.
            </td>
          </tr>
          <tr>
            <td>Powierzchnia użytkowa:</td>
            <td>13 m<sup>2</sup></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
