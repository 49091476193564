import React from "react";
import "./ProjektyKatalogowe.scss";

export default function ProjektyKatalogowe() {
  return (
    <div className="projekty-katalogowe">
      <h4>DLACZEGO NIE WARTO KUPOWAĆ GOTOWYCH PROJEKTÓW KATALOGOWYCH:</h4>
      <ol>
        <li>
          Nigdy w pełni nie spełnią one wymagań użytkowników co do ilości i
          wielkości pomieszczeń oraz ich przeznaczenia.
        </li>
        <li>
          Są niedostosowane do lokalizacji, kierunków świata i nasłonecznienia.
        </li>
        <li>
          Nie jest to opracowanie umożliwiające uzyskanie pozwolenia na budowę.
        </li>
        <li>
          Występowanie w nich ukrytych wad funkcjonalnych czy konstrukcyjnych
          jest częste.
        </li>
        <li>
          Rozwiązania instalacji wewnętrznych są schematyczne i nie dostosowane
          do lokalnych możliwości.
        </li>
      </ol>
      <h4 className="top-space">WARTO PRZECZYTAĆ:</h4>
      <ul>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            alt=""
            href="http://www.architekt.bedzin.pl/blog/projekt-katalogowy-czyli-tanio-wcale-nie-znaczy-dobrze/"
          >
            http://www.architekt.bedzin.pl/blog/projekt-katalogowy-czyli-tanio-wcale-nie-znaczy-dobrze/
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            alt=""
            href="https://panidyrektor.pl/projekt-typowy-domu-z-katalogu-i-projekt-indywidualny-znajdz-roznice/"
          >
            https://panidyrektor.pl/projekt-typowy-domu-z-katalogu-i-projekt-indywidualny-znajdz-roznice/
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            alt=""
            href="https://budujemydom.pl/budowlane-abc/budowa-krok-po-kroku/a/14390-nie-ma-sensu-przeplacac-kupujac-projekt-katalogowy"
          >
            https://budujemydom.pl/budowlane-abc/budowa-krok-po-kroku/a/14390-nie-ma-sensu-przeplacac-kupujac-projekt-katalogowy
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            alt=""
            href="https://budujemydom.pl/budowlane-abc/domy-pod-klucz/a/10873-budowac-z-architektem-czy-wedlug-projektu-katalogowego"
          >
            https://budujemydom.pl/budowlane-abc/domy-pod-klucz/a/10873-budowac-z-architektem-czy-wedlug-projektu-katalogowego
          </a>
        </li>
      </ul>
    </div>
  );
}
