import React from "react";
import { Envelope, Mobile, Facebook } from "./Icons";
import "./TopBar.scss";

export default () => {
  return (
    <div className="top-bar">
      <a href="mailto:biuro@liniaiforma.pl" className="top-bar-link">
        <Envelope className="icon" />
        biuro@liniaiforma.pl
      </a>
      <a href="tel:+48 602 195 984" className="top-bar-link">
        <Mobile className="icon" />
        +48 602 195 984
      </a>
      <a
        className="top-bar-link link-last"
        href="https://www.facebook.com/pg/LINIA-I-FORMA-Architekt-Magdalena-Zieli%C5%84ska-1982038492098662/about/?ref=page_internal"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Facebook className="icon" />
      </a>
    </div>
  );
};
