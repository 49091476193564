import React from "react";

const bars = {
  viewBox: "0 0 448 512",
  children: (
    // tslint:disable-next-line:max-line-length
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
  )
};

const envelope = {
  viewBox: "0 0 512 512",
  children: (
    // tslint:disable-next-line:max-line-length
    <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" />
  )
};

const mobile = {
  viewBox: "0 -25 320 562",
  children: (
    // tslint:disable-next-line:max-line-length
    <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" />
  )
};

const facebook = {
  viewBox: "0 0 448 512",
  children: (
    // tslint:disable-next-line:max-line-length
    <path d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z" />
  )
};

const SquareIcon = ({ viewBox, ...iconProps }) => props => (
  <svg
    viewBox={squareViewBox(viewBox)}
    {...iconProps}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  />
);

export const Envelope = SquareIcon(envelope);
export const Mobile = SquareIcon(mobile);
export const Facebook = SquareIcon(facebook);
export const Menu = SquareIcon(bars);

function squareViewBox(viewBox) {
  const [widthOffset, heightOffset, width, height] = viewBox
    .split(" ")
    .map(Number);
  const diff = width - height;
  if (diff > 0) {
    return [widthOffset, heightOffset - diff / 2, width, height + diff].join(
      " "
    );
  }
  if (diff < 0) {
    return [widthOffset + diff / 2, heightOffset, width - diff, height].join(
      " "
    );
  }
  return viewBox;
}
