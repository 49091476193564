import React from "react";
import "./Oferta.scss";

export default function() {
  return (
    <div className="oferta">
      <p>ZAKRES USŁUG PROJEKTOWYCH OBEJMUJE:</p>
      <ul>
        <li>
          Analizy przedprojektowe - określające możliwości lokalizacyjne,
          gabarytowe i programowe dla planowanej inwestycji
        </li>
        <li>
          Projekt koncepcyjny - przedstawiający podstawowe założenia projektowe
          z uwzględnieniem wytycznych branżowych
        </li>
        <li>
          Projekt budowlany - wielobranżowe opracowanie zawierające projekty:
          zagospodarowania terenu, architektury, konstrukcji, instalacji
          wewnętrznych oraz wszelkie formalno-prawne uzgodnienia i opinie
          niezbędne do uzyskania pozwolenia na budowę
        </li>
        <li>Kosztorys inwestorski - zbiorcze zestawienie kosztów inwestycji</li>
        <li>
          Projekt wykonawczy - szczegółowe rozwiązania techniczne niezbędne w
          czasie realizacji inwestycji
        </li>
        <li>
          Nadzór autorski - kontrola zgodności realizowanych prac z projektem,
          uzgadnianie i opiniowanie ewentualnych zamiennych rozwiązań
          technicznych
        </li>
        <li>
          Projekt wnętrz - kompletne opracowanie zawierające szczegóły
          wykończenia wnętrz wraz z wyposażeniem
        </li>
        <li>
          Kierowanie robotami budowlanymi - pełnienie funkcji kierownika budowy
          dla prostych obiektów, takich jak domy jednorodzinne
        </li>
      </ul>
    </div>
  );
}
