import React from "react";
import { Router } from "@reach/router";
import { routes } from "./routes";

const Routes = (
  <Router primary={false}>
    {routes.map(({ Component, route }) => {
      return <Component key={route} path={route} />;
    })}
  </Router>
);

export default () => {
  return <section className="content">{Routes}</section>;
};
