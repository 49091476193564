import Home from "./Home";
import OPracowni from "./OPracowni";
import Oferta from "./Oferta";
import Projekty from "./Projekty";
import Kontakt from "./Kontakt";
import Projekt1 from "./Projekt1";
import Projekt2 from "./Projekt2";
import Projekt3 from "./Projekt3";
import ProjektyKatalogowe from "./ProjektyKatalogowe";

export const subpageRoutes = [
  {
    title: "O pracowni",
    route: "/o-pracowni",
    Component: OPracowni
  },
  {
    title: "Oferta",
    route: "/oferta",
    Component: Oferta
  },
  {
    title: "Projekty",
    route: "/projekty",
    Component: Projekty
  },
  {
    title: "Kontakt",
    route: "/kontakt",
    Component: Kontakt
  }
];

export const auxiliaryRoutes = [
  {
    title: "dlaczego nie warto kupować gotowych projektów katalogowych",
    route: "/dlaczego-nie-warto-kupowac-gotowych-projektow-katalogowych",
    Component: ProjektyKatalogowe
  }
];

export const projektyRoutes = [
  {
    title: "Dom jednorodzinny w Piwnicznej-Zdroju",
    route: "/projekty/projekt1",
    Component: Projekt1
  },
  {
    title: "Kuchnia w domu jednorodzinnym w Rabce-Zdroju",
    route: "/projekty/projekt2",
    Component: Projekt2
  },
  {
    title: "Wnętrze domu w Piwnicznej-Zdroju",
    route: "/projekty/projekt3",
    Component: Projekt3
  }
];

export const homeRoute = {
  title: "Linia i Forma",
  route: "/",
  Component: Home
};

export const routes = [
  ...subpageRoutes,
  homeRoute,
  ...projektyRoutes,
  ...auxiliaryRoutes
];
