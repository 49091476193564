import React from "react";
import "./Projekt.scss";

export default function Projekt1() {
  return (
    <div className="projekt">
      <img src="/wizualizacje/wizualizacja4.jpg" alt="" />
      <img src="/projekt3/schemat.svg" alt="" />
      <Description />
    </div>
  );
}

function Description() {
  return (
    <div>
      <h2>STREFA DZIENNA DOMU JEDNORODZINNEGO</h2>
      <table>
        <tbody>
          <tr>
            <td>Lokalizacja:</td>
            <td>Kosarzyska, Piwniczna-Zdrój, województwo małopolskie, Polska </td>
          </tr>
          <tr>
            <td>Projekt:</td>
            <td>2018 r.</td>
          </tr>
          <tr>
            <td>Powierzchnia użytkowa:</td>
            <td>
              43 m<sup>2</sup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
