import React from "react";
import { Link } from "@reach/router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.scss";

export default class SimpleSlider extends React.Component {
  render() {
    var settings = {
      infinite: true,
      speed: 3000,
      slidesToScroll: 1,
      pauseOnHover: false,
      lazyLoad: true,
      autoplay: true,
      fade: true,
      arrows: false
    };

    const commonPath = "/wizualizacje/wizualizacja";
    return (
      <div className="relative-ctx">
        <div className="img-box">
          <Slider {...settings}>
            <img className="img-box" alt="" src={`${commonPath}3.jpg`} />
            <img className="img-box" alt="" src={`${commonPath}4.jpg`} />
            <img className="img-box" alt="" src={`${commonPath}1.jpg`} />
            <img className="img-box" alt="" src={`${commonPath}2.jpg`} />
          </Slider>
        </div>
        <h4 className="sublink">
          <Link to="/dlaczego-nie-warto-kupowac-gotowych-projektow-katalogowych">
            ZOBACZ DLACZEGO NIE WARTO KUPOWAĆ GOTOWYCH PROJEKTÓW KATALOGOWYCH!
          </Link>
        </h4>
      </div>
    );
  }
}
